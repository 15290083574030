<template>
  <base-layout>
   <div class="day-selector" :style="{'background':primaryColor + '!important'}">
       <IonSegmentButton  class="day-selector-item" style="min-width:50px; width: 50px;max-width:50px" @click="changeToList()" :style="{'background':primaryColor + '!important'}">
          <ion-icon style="color: white" :icon="listOutline"></ion-icon>
      </IonSegmentButton>
      <div class="day-selector-item" v-for="(value, key) in days" :key="key" :value="key" :style="{'background':primaryColor + '!important'}"
      style="" mode="md"  @click="changeActiveDay(key) ">
      <div class="day-text d-md-none">  {{moment(key).format('ddd')}}  </div>
      <div v-if="activeDay == key" class="day-number-selected">
        <span class="punkt-selected d-md-none">{{ moment(key).format('D') }} </span>
        <span style="color:white" class="d-none d-md-block large-selected">{{moment(key).format('dddd, MMMM D')}}</span>
      </div>
      <div v-else class="day-number">
        <span class="d-md-none">{{ moment(key).format('D') }} </span>
        <span style="color:white" class="d-none d-md-block">{{moment(key).format('dddd, MMMM D')}}</span>
      </div>
      </div>
    </div>

    <ion-content scroll-y="false">
      <div style="" v-if="activeDay">
        <div class="corner"></div>
        <div class="sessionGridComponent has-header {{ !disableChange ? 'has-subheader' : ''}}" style="margin-top: -60px"
             v-bind:class="roomsOnHorizontal ? 'rooms-horizontal' : 'rooms-vertical'">
          <div class="session-grid-component" v-bind:style="sessionGridComponent(containerWidth)">
            <!--div class="corner-item" v-if="!syncActive" v-bind:style="cornerItem(disableChange, isIos)"></div--><!--v-bind:style="cornerItem(disableChange, isIos)"-->
            <div class="room-items">
              <div class="room-item"></div>
              <router-link class="room-item" style="color: #000; text-decoration: none" v-for="item in rooms"
                           :key="item" :to=" '/app/locations/'+item.id"><!--changed this from href to router-link-->
                <strong class="room-item">{{ item.name }}</strong>
              </router-link>
            </div>
            <div class="hours-grid">

              <div class="hour-items-row">
                <div class="hour-header-item" v-for="hour in hours" :key="hour">{{ hour }}:00</div>
              </div>
              <div v-for="item in rooms" :key="item" class="hour-items-row">
                <div v-for="hour in hours" :key="hour" class="hour-item"></div>

                <template v-for="slot in item.slots" :key="slot"> 
                  <div v-if="slot.sessions.length == 1" @click="showDisclaimer(slot.sessions[0])">

                    <a
                       class="session-item" v-bind:style="sessionPosition(slot.sessions[0])" style="position: absolute !important">
                      <div class="si-content" v-bind:style="siContent(slot.sessions[0].color,  slot.sessions[0].font_color)">
                        <div v-if=" slot.sessions[0].code" class="si-code" v-bind:style="siCode(slot.sessions[0])">
                          {{ slot.sessions.length > 1 ? '' : slot.sessions[0].code }}
                        </div>
                        <div style="margin-top:5px"  class="si-title" v-if="slot.sessions.length > 1" v-html="item.name">
                        </div>
                        <div style="margin-top:5px"  class="si-title" v-if="slot.sessions.length == 1" v-html="slot.sessions[0].title">
                        </div>
                      </div>
                    </a>

                   

                  </div>

                  <router-link v-if="slot.sessions.length != 1"
                               :to="'/app/locationsfiltered/' + item.id + '/' + slot.sessions[0].start_time + '/' + slot.sessions[0].end_time"> 
                    <a class="session-item" v-bind:style="sessionPosition(slot.sessions[0])">
                      <div class="si-content"
                           v-bind:style="siContent(item.id == 'da7ad0c0-3ed1-4500-1302-080000000018' ? '#7C002A' : slot.sessions[0].color, slot.sessions[0].font_color )">
                        <div class="si-code"
                             v-bind:style="siCode(item.id == 'da7ad0c0-3ed1-4500-1302-080000000018' ? {color:'#7C002A','font-color':'#fff'} : slot.sessions[0])">
                          {{ slot.sessions.length > 1 ? '' : slot.sessions[0].code }}
                        </div>
                       
                        <div class="si-title">
                          {{ slot.sessions.length > 1 ? item.name : slot.sessions[0].title }}
                        </div>
                      </div>
                    </a>
                  </router-link>
                </template>

              </div> <!-- changed break to breakUnit (break is reserved) -->
              <div v-for="breakUnit in breaks" :key="breakUnit" class="session-item is-break "
                   v-bind:style="breakPosition(breakUnit, rooms.length)">
                <div class="break-title rotated">
                  <div v-for="pass in range" :key="pass"
                       style="display: inline-block; ">
                    <p v-bind:style="pStyle(blockSize)">{{ breakUnit.title }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="filler"></div>
          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {alertController, IonSegmentButton} from '@ionic/vue';
import {defineComponent} from 'vue';
import moment from 'moment';
import {mapActions, mapState} from 'vuex';
import {isPlatform} from '@ionic/vue';
import {listOutline, searchOutline, chevronForward, chevronBack} from 'ionicons/icons';

export default defineComponent({
  name: 'GridList',
  components: {
    IonSegmentButton,
  },

  setup() {
    return {
      listOutline,
      searchOutline,
      chevronForward,
      chevronBack
    }
  },
  data() {
    return {
      //SCHEDULE AND SESSIONS
      sessions: null,
      days: {},
      activeDay: '',

      //GRID VARIABLES
      disableChange: true,
      isIos: null,
      widthMultiplier: 4,//2.3333333333333333333333, //60 * 2.3 = 140px
      heightMultiplier: 1,//1, //60 * 1 = 60px
      hours: [],
      day: moment().date(), //check this later
      roomsOnHorizontal: true,
      format: '',
      gridDays: [],

      //GRID VARIABLES TO BE SET ONCE WE GOT THE SESSION GRID DATA.
      rooms: null,
      breaks: null,
      dayStart: null,
      dayEnd: null,
      blockSize: null,
      containerWidth: null,
      range: null,
      search: "",
      filtered: []
    }
  },


  methods: {
    ...mapActions('sessions', ['getDays', 'getGridData']),
    ...mapActions('googleanalytics', ['trackWithLabel']),

     dayUp(){

      let backUpDay = this.activeDay
      let nextDay;
      console.log(Object.keys(this.days)[1])
      for(let i = 0; i< Object.keys(this.days).length; i++){
        if(this.activeDay == Object.keys(this.days)[i]){
          nextDay = Object.keys(this.days)[i+1]
        }
      }
      if(nextDay == undefined){
        nextDay = backUpDay
      }
      this.activeDay = nextDay;
      this.fetchSessions();
    },

    dayDown(){
      let backUpDay = this.activeDay
      let prevDay;
      for(let i = 0; i< Object.keys(this.days).length; i++){
        if(this.activeDay == Object.keys(this.days)[i]){
          prevDay = Object.keys(this.days)[i-1]
        }
      }
       if(prevDay == undefined){
        prevDay = backUpDay
      }
      this.activeDay = prevDay;
      this.fetchSessions();
    },

    setGridData() {//TODO
      this.hours = []; //reset it on change, will have to check more variables to reset
      this.rooms = this.sessions.rooms.sort((a,b)=>a.order-b.order);
      this.breaks = this.sessions.breaks;
      this.dayStart = this.sessions.dayStart;
      this.dayEnd = this.sessions.dayEnd;
      for (let i = this.dayStart; i <= this.dayEnd; i++) {
        this.hours.push(i); //set the number of hours based on the difference between start and end
      }
      this.range = this.setRange();
      this.blockSize = this.rooms.length > 3 ? this.widthMultiplier * 180 : this.widthMultiplier * 60
      this.containerWidth = this.rooms.length * 200;
    },


    setRange() {
      let num = Math.ceil((Object.keys(this.rooms).length) / 3);
      return new Array(num);
    },

    async fetchSessions() {
      this.sessions = await this.getGridData(this.activeDay); //I get the data based on the active day
      this.setGridData();
      //this.prepareHeaders(); //look this up, order oof execution is probably not right
    },

    rotateView() {
      this.roomsOnHorizontal = !this.roomsOnHorizontal; //swap true to false 
      return true;
    },

    setActiveDayForToday() {
      for (let day in this.days) {
        if (day == moment().format('YYYY-MM-DD')) {
          this.activeDay = day;
        }
      }
      if (this.activeDay == null) {
        let daysArray = [];
        for (let d in this.days) {
          daysArray.push(d);
        }
        this.activeDay = daysArray[0]
      }
    },

    changeActiveDay(selectedDay) {
      this.activeDay = selectedDay;

      this.fetchSessions();

    },

    //STYLING

    // Berechnet die Breite und horizontale Position des Session-Items basierend auf Start- und Endzeit
    sessionPosition(item) {
      let startTmp = item.start_time.split('T')[1];
      let startTmpHours = parseInt(startTmp.split(':')[0])
      let startTmpMinutes = parseInt(startTmp.split(':')[1])

      let endTmp = item.end_time.split('T')[1];
      let endTmpHours = parseInt(endTmp.split(':')[0])
      let endTmpMinutes = parseInt(endTmp.split(':')[1])
      let startTime = startTmpHours;
      let endTime = endTmpHours;
      //startTime = new Date(item.start_time);
      //endTime = new Date(item.end_time);
      let dimension = this.roomsOnHorizontal ? 'height' : 'width';
      let translate = this.roomsOnHorizontal ? 'translateY(' : 'translateX(';
      let pixelMultiplier = this.roomsOnHorizontal ? this.heightMultiplier : this.widthMultiplier;

      console.log("AQUI")
      console.log(  'transform'+ translate + (((startTime - this.dayStart) * 60 + startTmpMinutes)) * pixelMultiplier + 'px)',)
      return {
        [dimension]: ((endTime - startTime) * 60 + endTmpMinutes - startTmpMinutes) * pixelMultiplier + 'px',
        'transform': translate + (((startTime - this.dayStart) * 60 + startTmpMinutes)) * pixelMultiplier + 'px)',
        //'background-color': item.color,
        //'color': item.font_color
      };
    },
cornerItem(disableChange, isIos) {
      if (disableChange) {
        return {
          'top': '119px',
        };
      } else if (isIos) {
        return {
          'top': 'calc(env(safe-area-inset-top) + 99px);',
        }
      } else {
        return {
          'top': '88px',
        }
      }
    },


    breakPosition(item, roomsCounter) {
      console.log('position break', item)
      let startTmp = item.start_time.split('T')[1];
      let startTmpHours = parseInt(startTmp.split(':')[0])
      let startTmpMinutes = parseInt(startTmp.split(':')[1])
      let endTmp = item.end_time.split('T')[1];
      let endTmpHours = parseInt(endTmp.split(':')[0])
      let endTmpMinutes = parseInt(endTmp.split(':')[1])
      let startTime = startTmpHours;
      let endTime = endTmpHours;
      //startTime = new Date(item.start_time);
      //endTime = new Date(item.end_time);
      let dimensionA = this.roomsOnHorizontal ? 'height' : 'width';
      let dimensionB = this.roomsOnHorizontal ? 'width' : 'height';
      let translate = this.roomsOnHorizontal ? 'translateY(' : 'translateX(';
      let pixelMultiplierA = this.roomsOnHorizontal ? this.widthMultiplier : this.heightMultiplier;
      let pixelMultiplierB = this.roomsOnHorizontal ? this.heightMultiplier : this.widthMultiplier;
      return {
        [dimensionA]: ((endTime - startTime) * 60 + endTmpMinutes - startTmpMinutes) * pixelMultiplierB + 'px',
        'transform': translate + (((startTime - this.dayStart) * 60 + startTmpMinutes)) * pixelMultiplierB + 'px)',
        'background-color': item.color,
        'color': item.font_color,
        [dimensionB]: roomsCounter * 60 * pixelMultiplierA + 'px'
      };
    },

    sessionGridComponent(containerWidth) {
      return {
        'width': containerWidth + 'px',
      };
    },

    pStyle(blockSize) {
      return {
        'width': blockSize + 'px !important',
      }
    },

    siContent(color, font_color) {
      return {
        'background': color,
        'color' : font_color
      };
    },

    siCode(session) {
      let color = session.color;
      let fontColor = session.font_color;

      return {
        'background-color': color,
        'color': fontColor,
        'min-height': '15px',
        'padding': session.code ? '3px 2px': '0'
      };
    },

    changeToList() {
      this.$router.push('/app/sessionList');
    },

    isLessThanNinety(start, end) {
      let duration = moment.duration(moment(end).diff(moment(start)));
      if (duration.asMinutes() < 90) {
        return true;
      } else {
        return false;
      }
    },
    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    if (session.display_type && session.display_type == 'collection') {
                      this.$router.push('/app/collections/' + session.id);
                    } else {
                      this.$router.push('/app/sessions/' + session.id);
                    }                  },
                },
              ],
            });
        return alert.present();
      } else {
        if (session.display_type && session.display_type == 'collection') {
          this.$router.push('/app/collections/' + session.id);
        } else {
          this.$router.push('/app/sessions/' + session.id);
        }      }

    }


  },

  async created() {
    this.isIos = isPlatform('ios')
    //this.activeDay = this.$route.params.day;
    this.moment = moment;
    this.moment.locale(this.currentLanguage || 'en');
    this.days = await this.getDays();
    this.setActiveDayForToday();
    this.fetchSessions();
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor', 'menuheader']),

  },

  watch: {
  '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'GridList') {
          this.isIos = isPlatform('ios')
          this.activeDay = localStorage.getItem('lastViewedDay') || this.$route.params.day;
          this.moment = moment;
          this.days = await this.getDays();
          this.setActiveDayForToday();
          this.fetchSessions();
        }else{
          if(this.activeDay){
            localStorage.setItem('lastViewedDay', this.activeDay)
          }
        }
      }
    },
    updateFinished: async function (newVal) {
      if (newVal) {
        this.isIos = isPlatform('ios')
        this.activeDay = this.$route.params.day;
        this.moment = moment;
        this.days = await this.getDays();
        this.setActiveDayForToday();
        this.fetchSessions();

      }
    },
  }

});

</script>


<style lang="scss">
.corner{
  position: sticky;
  /* display: contents; */
  left: 0;
  top: 0;
  z-index: 500;
  background-color: #F5F5F5;
  min-width: 80px;
  width: 80px;
  height: 60px;
  border-right: solid 1px rgba(0, 0, 0, 0.125);
  border-bottom: solid 2px rgba(0, 0, 0, 0.125);
  border-top: solid 2px rgba(0, 0, 0, 0.125);
  justify-content: center;
  align-items: center;
}


  .day-selector{
    width: 100%;
    padding: 10px;
    padding-top: 10px;
    display: flex;
   flex-direction: row;
   align-items: center;
  }

  .day-selector:hover{
    cursor: pointer;
  }

  .day-selector-item{
    text-align: center;
     width: 100%;
    position: relative;
    display: block;
  }


  .day-text{
     width: 100%;
     text-transform: uppercase;
     font-weight: 600;
     color: white;
  }
  .day-number{
      width: 100%;
      color: white;
      
  }
  .day-number-selected{
  }

  .punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.large-selected{
font-weight: 900;
}


</style>
